const ID_TOKEN_KEY = "user_token" as string;
const TOKEN = "g8bqYVlMgnBFXNwM2cOYNR-p2p57iFN9" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getAuthToken = (): string => {
  return TOKEN;
};

export default { getToken, saveToken, destroyToken, getAuthToken };
